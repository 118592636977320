
import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { Grid, Typography, Paper, TextField, Button,Snackbar } from "@mui/material";

import ButtonLoading from '../../constant/ButtonLoading';
import {
  globalAddPaper,
  globalStyle,
  globalModalBtnStyle,
  globalModalTitleStyle,
  globalModalTextfieldLabelStyle,
  globalModalTextfieldStyle,
} from "../../constant/globalStyle";
import { useId } from "react";
import { uploadFileFormat } from "../../constant/uploadFileFormat";
const validationSchema = yup.object().shape({
  totalPrice: yup.string().required("Total Price is required"),
  fileUpload: yup.string(),
  specialNote: yup.string(),
  expiryDate: yup.date().required("Expiry date is required") 
});

const AddBid = () => {
  const idh = useId();
  const [openSnackbar, setOpenSnackbar] = useState(false);
const [drawingFile,setDrawingFile]=useState("");
const [proposalFile,setProposalFile]=useState("");
const [additionalDocuments,setAdditionalDocuments]=useState("");
  const initialValues = {
    totalPrice:"" ,
    drawingFile:null ,       
    proposalFile:null ,
           additionalDocuments :"" ,
                  offerExpiryDate:[]  ,
                       description:"",
    
  };

  // const handleFileUpload = (event, setter) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setter(file.name);
  //     setTimeout(() => {
  //       setOpenSnackbar(true);  // Show success message
  //     }, 1500);
  //   }
  // };
  const handleDrawingFile = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
        setFieldValue('drawingFile', file);  // Setting the file object directly
        setTimeout(() => {
            setOpenSnackbar(true); // Show success message
        }, 1500);
    } else {
        setFieldValue('drawingFile', null);  // Ensure null is set if no file is selected
    }
  };
  const handleProposalFile= (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
        setFieldValue('proposalFile', file);  // Setting the file object directly
        setTimeout(() => {
            setOpenSnackbar(true); // Show success message
        }, 1500);
    } else {
        setFieldValue('proposalFile', null);  // Ensure null is set if no file is selected
    }
  };

  const handleFileChange = (event, setFieldValue, fieldName) => {
    const files = event.target.files;
    if (files && files.length > 0) {
        const fileList = Array.from(files);  // Convert FileList to Array
        setFieldValue(fieldName, fileList);  // Update Formik's state with all files
    } else {
        console.error("No files found in the event object");
        setFieldValue(fieldName, []);  // Ensure existing files are cleared if no files selected
    }
};
  const fileDisplay = files => files.map((file, index) => (
    <Typography key={index}>{uploadFileFormat(file.name)} - {Math.round(file.size / 1024)} KB</Typography>
  ));
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const onSubmitHandle = async (values) => {
    console.log(values);
  };
  const commonAreaStyle = { height: '49px', width:"550px",marginLeft:"12px"};
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <Paper elevation={1} sx={{ ...globalAddPaper }}>
            <Grid container spacing={2}>
              {/* Existing fields and components */}
              <Grid item xs={12} sm={12}>
               <Typography sx={{ ...globalModalTitleStyle, p: 2 }}>
                 Company Proposal 
                </Typography>
             </Grid>

              {/* Existing fields and components */}
              
         {/* Existing Field Inputs */}
                         
             

              <Grid item xs={12} sm={6} sx={{ p: 2 }}>
                <Field name="totalPrice">
                  {({ field, meta }) => (
                    <TextField
                      required
                      id={`${idh}-totalPrice`}
                      label="Total Price"
                      fullWidth
                      value={field.value}
                      onChange={(event) => setFieldValue("Total Price", event.target.value)}
                      InputLabelProps={{ shrink: true, style: { ...globalModalTextfieldLabelStyle } }}
                      InputProps={{
                        style: { ...globalStyle, ...globalModalTextfieldStyle,...commonAreaStyle , borderRadius: "5px" },
                      }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field name="offerExpiryDate">
                  {({ field, form, meta }) => (
                    <TextField
                      id={`${idh}-offerExpiryDate`}
                      label="Expected Date of Expiry"
                      type="offerExpiryDate"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => {
                        setFieldValue("offerExpiryDate", event.target.value);
                      }}
                      InputProps={{
                        style: { ...globalStyle, ...globalModalTextfieldStyle, ...commonAreaStyle, borderRadius: "5px" },
                      }}
                      {...field}
                      error={meta.touched && !!meta.error}
                      helperText={meta.touched ? meta.error : ""}
                    />
                  )}
                   </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                              <Field name="description">
                                {({ field }) => (
                                  <TextField
                                    id={`${idh}-description`}
                                    label="Any Special Note"
                                    fullWidth
                                    {...field}
                                    InputLabelProps={{ shrink: true, style: { ...globalModalTextfieldLabelStyle } }}
                                    InputProps={{ style: { ...globalStyle, ...globalModalTextfieldStyle,...commonAreaStyle, borderRadius: "5px" }}}
                                  />
                                )}
                              </Field>
                            </Grid>
              {/* New File Upload Button */}
              <Grid item xs={12} sm={6} sx={{ p: 2 }}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ ...globalModalBtnStyle,height:"49px", width:"205px", ml: 2 }} // Adjust margin as needed
                >
                  Upload drawing File 
                  {/* <input
                    type="file"
                    hidden
                    onChange={handleFileUpload}
                  /> */}
                   <input
                                      type="file"
                                      hidden
                                      onChange={(event) => handleDrawingFile(event, setFieldValue)}
                                    />
{/* 
<input
    type="file"
    hidden
    onChange={(event) => handleFileUpload(event, setFieldValue)}
/> */}


                </Button>
                {/* {uploadedFile && <Typography sx={{ ml: 2 }}>{uploadedFile}</Typography>} */}
                {drawingFile && (
                  <Typography sx={{ml:1.5,mt:1}}>
{uploadFileFormat(drawingFile)}

                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={6} sx={{ p: 2 }}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ ...globalModalBtnStyle, height:"49px",width:"205px",ml: 2 }} // Adjust margin as needed
                >
                  Upload Proposal File 
                  {/* <input
                    type="file"
                    hidden
                    onChange={(event)=>handleFileUpload(event,setProposalFile)}
                  /> */}
                   <input
                                      type="file"
                                      hidden
                                      onChange={(event) => handleProposalFile(event, setFieldValue)}
                                    />
                </Button>
                {/* {proposalFile && <Typography sx={{ ml: 2 }}>{uploadedFile}</Typography>} */}
{proposalFile && (
  <Typography sx={{ml:2,mt:1}}>
    {uploadFileFormat(proposalFile)}
  </Typography>
)}

              </Grid>
              {/* Existing radio buttons and other components */}
              
              <Grid item xs={12} sm={6} sx={{ p: 2 }}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ ...globalModalBtnStyle,height:"49px", ml: 2 }} // Adjust margin as needed
                >
                  Upload any additional file
                  {/* <input
                    type="file"
                    hidden
                    onChange={(event)=>handleFileUpload(event,setAdditionalFile)}
                  />
                </Button>
            
                {additionalFile && (
                  <Typography sx={{ml:2,mt:1}}>
                    {uploadFileFormat(additionalFile)}
                  </Typography>
                )} */}

<input          
  type="file"
  multiple
  onChange={(event) => handleFileChange(event, setFieldValue, 'additionalDocuments')}
            />
          </Button>
          {additionalDocuments.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              {fileDisplay(additionalDocuments)}
            </div>
          )}
              </Grid>
              {/* Submission and other parts of the form */}
              <Grid item xs={12} sm={12} sx={{ display:"flex",justifyContent:"center",p: 2 }}>
                {isSubmitting && <ButtonLoading />}
                {!isSubmitting && (
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{
                      ...globalModalBtnStyle,
                      width: { xs: "100%", sm: "25%" },
                      height: "49px",
                      // float: "right",
                    }}
                  >
                    Save
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
          {/* Snackbar for success messages */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message="File uploaded successfully"
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
        </Form>
      )}
    </Formik>
  );
};
 export default AddBid;