// import { apiSlice } from "../../service/apiSlice"
// import { logOut,setToken } from "../shared/authSlice";
// export const quoteApiSlice = apiSlice.injectEndpoints({
//     endpoints: builder => ({
//         createQuote:builder.mutation({
//             query:(payload)=>({
//                     url:'/quote',
//                     method:"POST",
//                     body:payload
//                 }),          
//         }),
//     })
// })
// export const {
//     useCreateQuoteMutation,
// } = quoteApiSlice 



// // import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// // // import { selectCurrentToken } from './authSlice';  // Adjust the path as necessary to import your auth slice correctly
// // import {selectCurrentToken} from '../shared/authSlice'

// // // Setting up the base query with authorization headers
// // const baseQuery = fetchBaseQuery({
// //     baseUrl: 'http://localhost:3500',  // Your server's base URL
// //     prepareHeaders: (headers, { getState }) => {
// //         const token = selectCurrentToken(getState());
// //         if (token) {
// //             headers.set('Authorization', `Bearer ${token}`);
// //         }
// //         return headers;
// //     }
// // });

// // export const apiSlice = createApi({
// //     reducerPath: 'api',
// //     baseQuery: baseQuery,
// //     endpoints: builder => ({
// //         createQuote: builder.mutation({
// //             query: (payload) => ({
// //                 url: '/quote',
// //                 method: 'POST',
// //                 body: payload
// //             }),
// //         }),
// //         // Add other endpoints here
// //     })
// // });

// // export const { useCreateQuoteMutation } = apiSlice;



// import { apiSlice } from "../../service/apiSlice";
// import { logOut, setToken } from "../shared/authSlice"; // Make sure paths are correct.

// export const quoteApiSlice = apiSlice.injectEndpoints({
//     endpoints: builder => ({
//         createQuote: builder.mutation({
//             query: (payload) => ({
//                 url: '/quote',
//                 method: "POST",
//                 body: payload
//             }),
//         }),
//     })
// });

// export const {
//     useCreateQuoteMutation,
// } = quoteApiSlice;


// import { apiSlice } from './apiSlice';
import { apiSlice } from "../../service/apiSlice";
export const quoteApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createQuote: builder.mutation({
            query: (quoteData) => ({
                url: '/quote',
                method: 'POST',
                body: quoteData,
            }),
        }),
        // Add more endpoints as needed
        getAllQuotes: builder.query({
            query: () => `/quote/admin/quotes`,
            validateStatus: (response, result) => {
              return response.status === 200;
            },
            transformResponse: (response) => {
              return response?.data ?? [];
            },
            
          }),
      
deleteQuote: builder.mutation({
    query: ({ quoteId }) => ({
        url: `/quote/delete/${quoteId}`,
        method: "DELETE",
    }),
    onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
            const result = await queryFulfilled;
            console.log('Delete successful', result);
        } catch (error) {
            console.error('Delete failed', error);
        }
    }
}),




    }),
    overrideExisting: false,
});

export const {
    useCreateQuoteMutation,
    useGetAllQuotesQuery,
    useDeleteQuoteMutation,
    
} = quoteApiSlice;
