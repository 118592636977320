import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
  Paper,
} from "@mui/material";
import about1 from "../../assets/about/about_3_1.png";
import about2 from "../../assets/about/about_3_2.png";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import CableIcon from "@mui/icons-material/Cable";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";

const AboutSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("xs", "md"));

 
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        justifyContent: "center",
        mt: 0,
        overflow: "hidden",
        backgroundColor: "#003C5F",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: isMobile ? "100%" : "50%",
          display: "flex",
          justifyContent: "flex-end",

          px: 4,
          py: 4,
        }}
      >
        <img
          src={about2}
          alt="High ZIndex"
          style={{
            //   width: '50%',
            width: isMobile ? "45%" : "45%",
            height: "auto",
            position: "absolute",
            zIndex: 2,
          }}
        />
        {/* <Box
          sx={{
            position: "absolute",
            bottom: isMobile ? 30 : 40,
            right: 10,
            backgroundColor: "rgba(255, 0, 0, 0.7)",
            color: "white",
            zIndex: 3,
            p: 3,

            paddingRight: isMobile ? "50px" : isTablet ? "10px" : "40px",
            fontSize: isMobile ? "0.8rem" : "1rem",
            display: isMobile ? "none" : "block",
          }}
        >
          <Typography>22 Years Experience</Typography>
        </Box> */}

        {/* Image 3 - Lowest z-index */}
        <img
          src={about1}
          alt="Low Z-Index"
          style={{
            width: isMobile ? "100%" : "100%",
            // top: 60,
            paddingRight: isMobile ? "50px" : isTablet ? "10px" : "117px",
            paddingTop: isMobile ? "20px" : isTablet ? "50px" : "96px",
            height: "auto",
            zIndex: 1,
          }}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          marginLeft: { xs: 0, sm: 3, md: 4 },
          p: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, mb: 3, color: "#FFC400" }}
        >
          About InfraVerse
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "white" ,textAlign: "justify",}}>
          InfraVerse is at the forefront of technological innovation,
          spearheading a transformative shift in the electricity connection
          process within the UK's construction industry. Our pioneering digital
          platform stands as the premier centralized marketplace, bridging the
          gap between customers – including developers, consultants, and
          construction firms – and key industry players such as Independent
          Connection Providers (ICPs), Distribution Network Operators (DNOs),
          and Independent Distribution Network Operators (IDNOs).
        </Typography>
        <Grid container spacing={2} justifyContent="space-around">
          {[
            {
              icon: <EmojiObjectsIcon fontSize="large" />,
              text: "Transparent Pricing",
            },
            {
              icon: <CableIcon fontSize="large" />,
              text: "Real Time Tracking",
            },
            {
              icon: <LocalFireDepartmentIcon fontSize="large" />,
              text: "Warehouse Storage",
            },
          ].map((feature, index) => (
            <Grid item xs={7} sm={6} md={4} key={index}>
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  borderRadius: "10px",
                  textAlign: "center",
                  backgroundColor: "#29A2CC",
                }}
              >
                {feature.icon}
                <Typography variant="subtitle1">{feature.text}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pt: 4,
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#FFC400",
              color: "black",
              borderRadius: "10px",
            }}
          >
            Learn More
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutSection;
