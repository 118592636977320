// import * as React from "react";
// import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
// // import { logOut, selectCurrentToken } from "../redux/shared/authSlice";
// import { logOut, selectCurrentToken } from "../redux/reducer/shared/authSlice";
// import { useSendLogOutMutation } from "../redux/reducer/api/authApiSlice";
// import { useSelector, useDispatch } from "react-redux";
// import {
//   Box,
//   AppBar,
//   Toolbar,
//   Button,
//   Menu,
//   MenuItem,
//   Typography,
//   Drawer,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import Divider from "@mui/material/Divider";
// import logo from "../assets/header/logo.png";
// import { Link, useNavigate } from "react-router-dom";
// // import { px } from 'framer-motion';

// const logoStyle = {
//   width: "180px",
//   height: "150px",
//   cursor: "pointer",
// };

// function Header() {
//   const [open, setOpen] = React.useState(false);
//   const accessToken = useSelector(selectCurrentToken);
//   const [featuresMenuOpen, setFeaturesMenuOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const navigate = useNavigate();

// console.log("Token isssssssssssssssss: ",accessToken )
// const dispatch = useDispatch();
//   const [sendLogOut] = useSendLogOutMutation();
  
//   const handleLogout = async () => {
//     try {
//       // Send logout request to the server
//       await sendLogOut();

//       // Dispatch logout action to clear the token in the Redux store
//       dispatch(logOut());
//     } catch (error) {
//       console.error("Logout error:", error);
//     }
//   };



//   const toggleDrawer = (newOpen) => () => {
//     setOpen(newOpen);
//   };

//   const handleSignUp = () => {
//     navigate("/signup");
//   };
//   const handleSignIn = () => {
//     navigate("/login");
//   };
 

//   const scrollToSection = (sectionId) => {
//     const sectionElement = document.getElementById(sectionId);
//     const offset = 128;
//     if (sectionElement) {
//       const targetScroll = sectionElement.offsetTop - offset;
//       sectionElement.scrollIntoView({ behavior: "smooth" });
//       window.scrollTo({
//         top: targetScroll,
//         behavior: "smooth",
//       });
//       setOpen(false);
//     }
//   };

//   const handleFeaturesMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//     setFeaturesMenuOpen(true);
//   };

//   const handleFeaturesMenuClose = () => {
//     setAnchorEl(null);
//     setFeaturesMenuOpen(false);
//   };

//   return (
//     <div>
//       <AppBar
//         position="fixed"
//         sx={{
//           boxShadow: 0,
//           bgcolor: "white",
//           backgroundImage: "none",
//         }}
//       >
//         <Box maxWidth="xl">
//           <Toolbar
//             variant="regular"
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               bgcolor: "rgba(255, 255, 255, 0.4)",
//               backdropFilter: "blur(24px)",
//               height: "100px",
//               //border: '1px solid',
//               //  borderColor: 'divider',
//               boxShadow:
//                 "0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)",
//             }}
//           >
//             <Box
//               sx={{
//                 flexGrow: 1,
//                 display: "flex",
//                 alignItems: "center",
//                 // ml: '-18px',
//                 // mr:'280px',
//               }}
//             >
//               <Link to="/">
//                 <img src={logo} style={logoStyle} alt="logo of sitemark" />
//               </Link>
//               {/* <TextField
//                 variant="outlined"
//                 size="small"
//                 placeholder="Search..."
//                 sx={{
//                   ml: 2, // Margin left to separate from logo
//                   display: { xs: 'block', sm: 'block' }, // Only show on sm screens and up
//                   bgcolor: 'background.paper',
//                   borderRadius: 1,
//                   width: 'auto', // Adjust width as necessary
//                   ml:'58px',
                  
//                   transition: "background-color 0.3s",
//             '&:hover': {
//               backgroundColor: "#FFC400",
//             }
//                 }}
//               /> */}
//               <Box sx={{ display: { xs: "none", md: "flex" } }}>
//                 <div>
//                   <Button
//                     onClick={handleFeaturesMenuClick}
//                     sx={{
//                       py: "6px",
//                       pl: "12px",
//                       ml: "48px",
//                       transition: "background-color 0.3s",
//                       "&:hover": {
//                         backgroundColor: "#FFC400",
//                       },
//                     }}
//                     endIcon={<ArrowDropDownIcon />}
//                   >
//                     <Typography variant="body2" color="text.primary">
//                       Our Services
//                     </Typography>
//                   </Button>
//                   <Menu
//                     anchorEl={anchorEl}
//                     open={featuresMenuOpen}
//                     onClose={handleFeaturesMenuClose}
//                   >
//                     <MenuItem onClick={() => scrollToSection("subsection1")}>
//                       Subsection 1
//                     </MenuItem>
//                     <MenuItem onClick={() => scrollToSection("subsection2")}>
//                       Subsection 2
//                     </MenuItem>
//                     <MenuItem onClick={() => scrollToSection("subsection3")}>
//                       Subsection 3
//                     </MenuItem>
//                   </Menu>
//                 </div>
//                 <MenuItem
//                   onClick={() => scrollToSection("testimonials")}
//                   sx={{
//                     py: "6px",
//                     px: "12px",
//                     transition: "background-color 0.3s",
//                     "&:hover": {
//                       backgroundColor: "#FFC400",
//                     },
//                   }}
//                 >
//                   <Typography variant="body2" color="text.primary">
//                     About
//                   </Typography>
//                 </MenuItem>
//                 <MenuItem
//                   onClick={() => scrollToSection("highlights")}
//                   sx={{
//                     py: "6px",
//                     px: "12px",
//                     transition: "background-color 0.3s",
//                     "&:hover": {
//                       backgroundColor: "#FFC400",
//                     },
//                   }}
//                 >
//                   <Typography variant="body2" color="text.primary">
//                     Team
//                   </Typography>
//                 </MenuItem>
//                 <MenuItem
//                   onClick={() => scrollToSection("pricing")}
//                   sx={{
//                     py: "6px",
//                     px: "12px",
//                     transition: "background-color 0.3s",
//                     "&:hover": {
//                       backgroundColor: "#FFC400",
//                     },
//                   }}
//                 >
//                   <Typography variant="body2" color="text.primary">
//                     Contact
//                   </Typography>
//                 </MenuItem>
//                 <MenuItem
//                   onClick={() => scrollToSection("faq")}
//                   sx={{
//                     py: "6px",
//                     px: "12px",
//                     transition: "background-color 0.3s",
//                     "&:hover": {
//                       backgroundColor: "#FFC400",
//                     },
//                   }}
//                 >
//                   <Typography variant="body2" color="text.primary">
//                     FAQ
//                   </Typography>
//                 </MenuItem>
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 display: { xs: "none", md: "flex" },
//                 gap: 0.5,
//                 alignItems: "center",
//               }}
//             >
//               <Button
//                 variant="contained"
//                 sx={{
//                   backgroundColor: "#FFC400",
//                   color: "black",
//                   fontWeight: "bold",
//                   "&:hover": {
//                     backgroundColor: "#ffd54f",
//                   },
//                 }}
//               >
//                 Track a Quote
//               </Button>

//               {/* <Button
//                 color="primary"
//                 variant="text"
//                 size="small"
//                 component="a"
//                 onClick={handleSignIn}
//                 target="_blank"
//               >
//                 Sign in
//               </Button>
//               <Button
//                 color="primary"
//                 variant="contained"
//                 size="small"
//                 component="a"
//                 onClick={handleSignUp}
//                 target="_blank"
//               >
//                 Sign up
//               </Button> */}
//               {accessToken ? (
//                 <Button
//                   color="primary"
//                   variant="contained"
//                   size="small"
//                   component="a"
//                   //href="/material-ui/getting-started/templates/sign-in/"
//                   target="_blank"
//                   onClick={handleLogout}
//                 >
//                   Logout
//                 </Button>
//               ) : (
//                 <Box sx={{ display: "flex", gap: "10px" }}>
//                   <Button
//                     color="primary"
//                     variant="contained"
//                     size="small"
//                     // onClick={handleLogin}
//                     onClick={handleSignIn}
//                   >
//                     Login
//                   </Button>
//                   <Button
//                     color="primary"
//                     variant="contained"
//                     size="small"
//                     onClick={handleSignUp}
//                   >
//                     Sign up
//                   </Button>
//                 </Box>
//               )}
//             </Box>
//             <Box sx={{ display: { sm: "", md: "none" } }}>
//               <Button
//                 variant="text"
//                 color="primary"
//                 aria-label="menu"
//                 onClick={toggleDrawer(true)}
//                 sx={{ minWidth: "30px", p: "4px" }}
//               >
//                 <MenuIcon />
//               </Button>
//               <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
//                 <Box
//                   sx={{
//                     minWidth: "60dvw",
//                     p: 2,
//                     backgroundColor: "background.paper",
//                     flexGrow: 1,
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       flexDirection: "column",
//                       alignItems: "end",
//                       flexGrow: 1,
//                     }}
//                   >
//                     {/* Additional settings for the drawer */}
//                   </Box>
//                   {/* <MenuItem onClick={() => scrollToSection('features')}>
//                     Features
//                   </MenuItem> */}

//                   <MenuItem
//                     onClick={() => scrollToSection("features")}
//                     sx={{ py: "6px", px: "12px" }}
//                   >
//                     {/* <Typography variant="body2" color="text.primary" sx={{ display: 'flex', alignItems: 'center' }}> */}
//                     Our Services <ArrowDropDownIcon />
//                     {/* </Typography> */}
//                   </MenuItem>
//                   <MenuItem onClick={() => scrollToSection("testimonials")}>
//                     About
//                   </MenuItem>
//                   <MenuItem onClick={() => scrollToSection("highlights")}>
//                     Team
//                   </MenuItem>
//                   <MenuItem onClick={() => scrollToSection("pricing")}>
//                     Contact
//                   </MenuItem>
//                   <MenuItem onClick={() => scrollToSection("faq")}>
//                     FAQ
//                   </MenuItem>
//                   <Divider />
//                   <MenuItem>
//                     <Button
//                       color="primary"
//                       variant="contained"
//                       size="small"
//                       component="a"
//                       // href="/Request"
//                       target="_blank"
//                       sx={{
//                         backgroundColor: "#FFC400",
//                         color: "black",
//                         width: "100%",
//                       }}
//                     >
//                       Track a Quote
//                     </Button>
//                   </MenuItem>
//                   {/* <MenuItem>
//                     <Button
//                       color="primary"
//                       variant="contained"
//                       component="a"
//                       onClick={handleSignUp}
//                       target="_blank"
//                       sx={{ width: "100%" }}
//                     >
//                       Sign up
//                     </Button>
//                   </MenuItem>
//                   <MenuItem>
//                     <Button
//                       color="primary"
//                       variant="outlined"
//                       component="a"
//                       onClick={handleSignIn}
//                       target="_blank"
//                       sx={{ width: "100%" }}
//                     >
//                       Sign in
//                     </Button>
//                   </MenuItem> */}
//                   {accessToken ? (
//                 <Button
//                   color="primary"
//                   variant="contained"
//                   size="small"
//                   component="a"
//                   //href="/material-ui/getting-started/templates/sign-in/"
//                   target="_blank"
//                   onClick={handleLogout}
//                 >
//                   Logout
//                 </Button>
//               ) : (
//                 <Box sx={{ display: "flex", gap: "10px" }}>
//                   <Button
//                     color="primary"
//                     variant="contained"
//                     size="small"
//                     // onClick={handleLogin}
//                     onClick={handleSignIn}
//                   >
//                     Login
//                   </Button>
//                   <Button
//                     color="primary"
//                     variant="contained"
//                     size="small"
//                     onClick={handleSignUp}
//                   >
//                     Sign up
//                   </Button>
//                 </Box>
//               )}
//                 </Box>
//               </Drawer>
//             </Box>
//           </Toolbar>
//         </Box>
//       </AppBar>
//     </div>
//   );
// }

// export default Header;









// // import * as React from "react";
// // // import PropTypes from "prop-types";
// // import { Link, useNavigate } from "react-router-dom";
// // import Box from "@mui/material/Box";
// // import AppBar from "@mui/material/AppBar";
// // import Toolbar from "@mui/material/Toolbar";
// // import Button from "@mui/material/Button";
// // import Badge from "@mui/material/Badge";
// // import Divider from "@mui/material/Divider";
// // import Typography from "@mui/material/Typography";
// // import MenuItem from "@mui/material/MenuItem";
// // import Drawer from "@mui/material/Drawer";
// // import MenuIcon from "@mui/icons-material/Menu";
// // import { ShoppingCart } from "@mui/icons-material";
// // // import ToggleColorMode from './ToggleColorMode';
// // import logo from "../assets/logo.webp";
// // // import { setCredentials } from "../../redux/shared/authSlice";
// // import { logOut, selectCurrentToken } from "../redux/shared/authSlice";
// // import { useSendLogoutMutation } from "../redux/reducer/api/authApiSlice";
// // import { useSelector, useDispatch } from "react-redux";

// // const logoStyle = {
// //   maxWidth: "100%",
// //   width: "70px",
// //   height: "auto",
// //   cursor: "pointer",
// //   padding: "0px 0px 0px 45px",
// // };

// // function Header() {
// //   const [open, setOpen] = React.useState(false);
// //   const token = useSelector(selectCurrentToken);

// //   //const {carts} = useSelector((state)=>state.allCart);

// //   const { carts } = useSelector((state) => state.cart);

// //   const dispatch = useDispatch();
// //   const [sendLogout] = useSendLogoutMutation();
// //   const navigate = useNavigate();
// //   const handleLogout = async () => {
// //     try {
// //       // Send logout request to the server
// //       await sendLogout();

// //       // Dispatch logout action to clear the token in the Redux store
// //       dispatch(logOut());
// //     } catch (error) {
// //       console.error("Logout error:", error);
// //     }
// //   };

// //   const toggleDrawer = (newOpen) => () => {
// //     setOpen(newOpen);
// //   };

// //   const scrollToSection = (sectionId) => {
// //     const sectionElement = document.getElementById(sectionId);
// //     const offset = 128;
// //     if (sectionElement) {
// //       const targetScroll = sectionElement.offsetTop - offset;
// //       sectionElement.scrollIntoView({ behavior: "smooth" });
// //       window.scrollTo({
// //         top: targetScroll,
// //         behavior: "smooth",
// //       });
// //       setOpen(false);
// //     }
// //   };
// //   const handleLogin = () => {
// //     navigate("/login");
// //   };
// //   const handleSignUp = () => {
// //     navigate("/signup");
// //   };
// //   return (
// //     <div>
// //       <AppBar
// //         position="fixed"
// //         sx={{
// //           boxShadow: 0,
// //           bgcolor: "transparent",
// //           backgroundImage: "none",
// //         }}
// //       >
// //         <Box maxWidth="xl">
// //           <Toolbar
// //             variant="regular"
// //             sx={{
// //               display: "flex",
// //               alignItems: "center",
// //               justifyContent: "space-between",
// //               flexShrink: 0,
// //               // bgcolor: 'rgba(255, 255, 255, 0.4)',
// //               bgcolor: "#663366",
// //               backdropFilter: "blur(24px)",
// //               maxHeight: 40,

// //               borderColor: "divider",
// //               boxShadow: `0 0 1px rgba(85, 166, 246, 0.1),
// //                 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15),
// //                 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`,
// //               borderBottom: "1px solid yellow",
// //             }}
// //           >
// //             <Box
// //               sx={{
// //                 flexGrow: 1,
// //                 display: "flex",
// //                 alignItems: "center",
// //                 ml: "-18px",
// //                 px: 0,
// //               }}
// //             >
// //            <MenuItem>
// //   <Link to="/" style={{ textDecoration: "none", color: "inherit", display: "flex", alignItems: "center" }}>
// //     <img src={logo} style={logoStyle} alt="logo of Sens tailor" />
// //     <Typography variant="body1" component="span" >
// //       ens Tailor
// //     </Typography>
// //   </Link>
// // </MenuItem>
// //               <Box sx={{ display: { xs: "none", md: "flex" } }}>
// //                 <MenuItem
// //                   onClick={() => scrollToSection("features")}
// //                   sx={{ py: "6px", px: "50px" }}
// //                 >
// //                   {token ? (
// //                     <Link
// //                       to="/calculator"
// //                       style={{ textDecoration: "none", color: "inherit" }}
// //                     >
// //                       Calculator
// //                     </Link>
// //                   ) : (
// //                     ""
// //                   )}
// //                 </MenuItem>

// //                 {/* <MenuItem
// //                   onClick={() => scrollToSection("highlights")}
// //                   sx={{ py: "6px", px: "12px" }}
// //                 >
// //                   <Typography variant="body2" color="text.primary">
// //                     Highlights
// //                   </Typography>
// //                 </MenuItem>
// //                 <MenuItem
// //                   onClick={() => scrollToSection("pricing")}
// //                   sx={{ py: "6px", px: "12px" }}
// //                 >
// //                   <Typography variant="body2" color="text.primary">
// //                     Pricing
// //                   </Typography>
// //                 </MenuItem>
// //                 <MenuItem
// //                   onClick={() => scrollToSection("faq")}
// //                   sx={{ py: "6px", px: "12px" }}
// //                 >
// //                   <Typography variant="body2" color="text.primary">
// //                     FAQ
// //                   </Typography>
// //                 </MenuItem> */}
// //               </Box>
// //             </Box>
// //             <Box
// //               sx={{
// //                 display: { xs: "none", md: "flex" },
// //                 gap: 0.5,
// //                 alignItems: "center",
// //               }}
// //             >
// //               {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
// //               {/* <Button
// //                 color="primary"
// //                 variant="text"
// //                 size="small"
// //                 component="a"
// //                 href="/material-ui/getting-started/templates/sign-in/"
// //                 target="_blank"
// //               >
// //                 Sign in
// //               </Button>
// //               <Button
// //                 color="primary"
// //                 variant="contained"
// //                 size="small"
// //                 component="a"
// //                 href="/material-ui/getting-started/templates/sign-up/"
// //                 target="_blank"
// //               >
// //                 Sign up
// //               </Button> */}
// //               <MenuItem sx={{ py: "6px", px: "12px" }}>
// //                 <Link
// //                   to="/cart"
// //                   style={{ textDecoration: "none", color: "inherit" }}
// //                 >
// //                   <Badge badgeContent={carts.length} color="error">
// //                     <ShoppingCart />
// //                   </Badge>
// //                 </Link>
// //               </MenuItem>
// //               {token ? (
// //                 <Button
// //                   color="primary"
// //                   variant="contained"
// //                   size="small"
// //                   component="a"
// //                   //href="/material-ui/getting-started/templates/sign-in/"
// //                   target="_blank"
// //                   onClick={handleLogout}
// //                 >
// //                   Logout
// //                 </Button>
// //               ) : (
// //                 <Box sx={{ display: "flex", gap: "10px" }}>
// //                   <Button
// //                     color="primary"
// //                     variant="contained"
// //                     size="small"
// //                     onClick={handleLogin}
// //                   >
// //                     Login
// //                   </Button>
// //                   <Button
// //                     color="primary"
// //                     variant="contained"
// //                     size="small"
// //                     onClick={handleSignUp}
// //                   >
// //                     Sign up
// //                   </Button>
// //                 </Box>
// //               )}
// //             </Box>
// //             <Box sx={{ display: { sm: "", md: "none" } }}>
// //               <Button
// //                 variant="text"
// //                 color="primary"
// //                 aria-label="menu"
// //                 onClick={toggleDrawer(true)}
// //                 sx={{ minWidth: "30px", p: "4px" }}
// //               >
// //                 <MenuIcon />
// //               </Button>
// //               <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
// //                 <Box
// //                   sx={{
// //                     minWidth: "60dvw",
// //                     p: 2,
// //                     backgroundColor: "background.paper",
// //                     flexGrow: 1,
// //                   }}
// //                 >
// //                   <Box
// //                     sx={{
// //                       display: "flex",
// //                       flexDirection: "column",
// //                       alignItems: "end",
// //                       flexGrow: 1,
// //                     }}
// //                   >
// //                     {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
// //                   </Box>
// //                   <MenuItem
// //                     onClick={() => scrollToSection("features")}
// //                     sx={{ py: "6px", px: "50px" }}
// //                   >
// //                     {token ? (
// //                       <Link
// //                         to="/calculator"
// //                         style={{ textDecoration: "none", color: "inherit" }}
// //                       >
// //                         Calculator
// //                       </Link>
// //                     ) : (
// //                       ""
// //                     )}
// //                   </MenuItem>
// //                   {/* <MenuItem onClick={() => scrollToSection("features")}>
// //                     Features
// //                   </MenuItem>
// //                   <MenuItem onClick={() => scrollToSection("testimonials")}>
// //                     Testimonials
// //                   </MenuItem>
// //                   <MenuItem onClick={() => scrollToSection("highlights")}>
// //                     Highlights
// //                   </MenuItem>
// //                   <MenuItem onClick={() => scrollToSection("pricing")}>
// //                     Pricing
// //                   </MenuItem>
// //                   <MenuItem onClick={() => scrollToSection("faq")}>
// //                     FAQ
// //                   </MenuItem> */}
// //                   <Divider />

// //                   {token ? (
// //                     <MenuItem>
// //                       <Button
// //                         color="primary"
// //                         variant="contained"
// //                         component="a"
// //                         //href="/material-ui/getting-started/templates/sign-up/"
// //                         target="_blank"
// //                         sx={{ width: "100%" }}
// //                         onClick={handleLogout}
// //                       >
// //                         Logout
// //                       </Button>
// //                     </MenuItem>
// //                   ) : (
// //                     <Box>
// //                       <MenuItem>
// //                         <Button
// //                           color="primary"
// //                           variant="contained"
// //                           component="a"
// //                           //href="/material-ui/getting-started/templates/sign-up/"
// //                           target="_blank"
// //                           sx={{ width: "100%" }}
// //                           onClick={handleSignUp}
// //                         >
// //                           Sign up
// //                         </Button>
// //                       </MenuItem>
// //                       <MenuItem>
// //                         <Button
// //                           color="primary"
// //                           variant="outlined"
// //                           component="a"
// //                           //href="/material-ui/getting-started/templates/sign-in/"
// //                           target="_blank"
// //                           sx={{ width: "100%" }}
// //                           onClick={handleLogin}
// //                         >
// //                           Sign in
// //                         </Button>
// //                       </MenuItem>
// //                       <MenuItem
// //                         sx={{
// //                           display: "flex",
// //                           alignItems: "center",
// //                           justifyContent: "center",
// //                           py: "6px",
// //                           px: "12px",
// //                         }}
// //                       >
// //                         <Link
// //                           to="/cart"
// //                           style={{
// //                             textDecoration: "none",
// //                             color: "inherit",
// //                             display: "flex",
// //                             alignItems: "center",
// //                           }}
// //                         >
// //                           <Badge badgeContent={carts.length} color="error">
// //                             <ShoppingCart />
// //                           </Badge>
// //                           <Typography variant="body1" sx={{ ml: 1 }}>
// //                             Subscription Cart
// //                           </Typography>
// //                         </Link>
// //                       </MenuItem>
// //                     </Box>
// //                   )}
// //                 </Box>
// //               </Drawer>
// //             </Box>
// //           </Toolbar>
// //         </Box>
// //       </AppBar>
// //     </div>
// //   );
// // }

// // export default Header;


import * as React from "react";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
// import { logOut, selectCurrentToken } from "../redux/shared/authSlice";
import { logOut, selectCurrentToken } from "../redux/reducer/shared/authSlice";
import { useSendLogOutMutation } from "../redux/reducer/api/authApiSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  MenuItem,
  Typography,
  Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import logo from "../assets/header/logo.png";
import { Link, useNavigate } from "react-router-dom";
import OurGoals from "../feature/MenuPage/OurGoals";
// import { px } from 'framer-motion';

const logoStyle = {
  width: "180px",
  height: "150px",
  cursor: "pointer",
};

function Header() {
  const [open, setOpen] = React.useState(false);
  const accessToken = useSelector(selectCurrentToken);
  const [featuresMenuOpen, setFeaturesMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

console.log("Token isssssssssssssssss: ",accessToken )
const dispatch = useDispatch();
  const [sendLogOut] = useSendLogOutMutation();
  
  const handleLogout = async () => {
    try {
      // Send logout request to the server
      await sendLogOut();

      // Dispatch logout action to clear the token in the Redux store
      dispatch(logOut());
    } catch (error) {
      console.error("Logout error:", error);
    }
  };



  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleSignUp = () => {
    navigate("/signup");
  };
  const handleSignIn = () => {
    navigate("/login");
  };

  const handleAboutus = () => {
    navigate("/aboutus");
  };
  const handleOurGoals = () => {
    navigate("/ourgoals");
  };

  const handleOurValues = () => {
    navigate("/ourvalues");
  };
 

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  const handleFeaturesMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setFeaturesMenuOpen(true);
  };

  const handleFeaturesMenuClose = () => {
    setAnchorEl(null);
    setFeaturesMenuOpen(false);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "white",
          backgroundImage: "none",
        }}
      >
        <Box maxWidth="xl">
          <Toolbar
            variant="regular"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "rgba(255, 255, 255, 0.4)",
              backdropFilter: "blur(24px)",
              height: "100px",
              //border: '1px solid',
              //  borderColor: 'divider',
              boxShadow:
                "0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                // ml: '-18px',
                // mr:'280px',
              }}
            >
              <Link to="/">
                <img src={logo} style={logoStyle} alt="logo of sitemark" />
              </Link>
             
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                
                <MenuItem
                 onClick={handleAboutus}
                  sx={{
                    py: "6px",
                    px: "12px",
                    pl: "12px",
                    ml: "48px",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: "#FFC400",
                    },
                  }}
                >
                  <Typography variant="body2" color="text.primary">
                  About Us
                  </Typography>
                </MenuItem>
               
                <MenuItem
                  onClick={handleOurGoals}
                  sx={{
                    py: "6px",
                    px: "12px",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: "#FFC400",
                    },
                  }}
                >
                  <Typography variant="body2" color="text.primary">
                  Our Goals
                  
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleOurValues}
                  sx={{
                    py: "6px",
                    px: "12px",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: "#FFC400",
                    },
                  }}
                >
                  <Typography variant="body2" color="text.primary">
                  Our Values
                  </Typography>
                </MenuItem>
                
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFC400",
                  color: "black",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#ffd54f",
                  },
                  
                }}
              >
                Track a Quote
              </Button>

              
              {accessToken ? (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  component="a"
                  //href="/material-ui/getting-started/templates/sign-in/"
                  target="_blank"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              ) : (
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    // onClick={handleLogin}
                    onClick={handleSignIn}
                  >
                    Login
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleSignUp}
                  >
                    Sign up
                  </Button>
                </Box>
              )}
            </Box>
            <Box sx={{ display: { sm: "", md: "none" } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: "30px", p: "4px" }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      flexGrow: 1,
                    }}
                  >
                    {/* Additional settings for the drawer */}
                  </Box>
                 

                  <MenuItem
                    onClick={handleAboutus}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    {/* <Typography variant="body2" color="text.primary" sx={{ display: 'flex', alignItems: 'center' }}> */}
                  About Us

                    {/* </Typography> */}
                  </MenuItem>
                 
                  <MenuItem onClick={handleOurGoals} >
                  Our Goals
                  
                  </MenuItem>
                  <MenuItem onClick={handleOurValues}>
                  Our Values
                  </MenuItem>
                  
                  <Divider />
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      component="a"
                      // href="/Request"
                      target="_blank"
                      sx={{
                        backgroundColor: "#FFC400",
                        color: "black",
                        width: "100%",
                      }}
                    >
                      Track a Quote
                    </Button>
                  </MenuItem>
                  
                  {accessToken ? (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  component="a"
                  
                  target="_blank"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              ) : (
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    // onClick={handleLogin}
                    onClick={handleSignIn}
                  >
                    Login
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleSignUp}
                  >
                    Sign up
                  </Button>
                </Box>
              )}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </div>
  );
}

export default Header;

