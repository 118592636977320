// export function uploadFileFormat(fileName) {
//     const maxWords = 4;
//     const maxChars = 30;
  
//     // First, trim to maxChars to ensure the filename isn't too long
//     let trimmed = fileName.slice(0, maxChars);
  
//     // Then trim by words, splitting by spaces and rejoining
//     trimmed = trimmed.split(' ').slice(0, maxWords).join(' ');
  
//     // Add ellipsis if the original filename is longer than the trimmed version
//     if (fileName.length > trimmed.length) {
//       trimmed += '...';
//     }
  
//     return trimmed;
//   }

export function uploadFileFormat(fileName) {
  const maxWords = 4;
  const maxChars = 30;

  // First, trim to maxChars to ensure the filename isn't too long
  let trimmed = fileName.slice(0, maxChars);

  // Then trim by words, splitting by spaces and rejoining
  let words = trimmed.split(' ');
  if (words.length > maxWords) {
    trimmed = words.slice(0, maxWords).join(' ');
  }

  // Add ellipsis if the original filename is longer than the trimmed version
  if (fileName.length > trimmed.length) {
    trimmed += '...';
  }

  return trimmed;
}
