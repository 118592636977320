// import { apiSlice } from "../../service/apiSlice";
// export const inquiryApiSlice = apiSlice.injectEndpoints({
//     endpoints: (builder) => ({
//         createInquiry: builder.mutation({
//             query: (payload) => ({
//                 url: '/inquiry',
//                 method: 'POST',
//                 body: payload,
//             }),
//         }),
//         // Add more endpoints as needed
//     }),
//     overrideExisting: false,
// });

// export const {
//     useCreateInquiryMutation,
// } = inquiryApiSlice;


import { apiSlice } from "../../service/apiSlice";
export const inquiryApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createInquiry: builder.mutation({
            query: (payload) => ({
                url: '/inquiry',
                method: 'POST',
                body: payload,
            }),
        }),
        // Add more endpoints as needed

        //get enquiry 
        getAllInquiries: builder.query({
            query: () => `/inquiry/admin/inquiries`,
            validateStatus: (response, result) => {
              return response.status === 200;
            },
            transformResponse: (response) => {
              return response?.data ?? [];
            },
          
          }),
    }),
    overrideExisting: false,
});

export const {
    useCreateInquiryMutation,
    useGetAllInquiriesQuery
} = inquiryApiSlice;





// import { apiSlice } from "../../service/apiSlice";

// export const inquiryApiSlice = apiSlice.injectEndpoints({
//   endpoints: (builder) => ({
//     createInquiry: builder.mutation({
//       query: (payload) => ({
//         url: '/inquiry',
//         method: 'POST',
//         body: payload,
//       }),
//     }),

//     getAllInquiries: builder.query({
//       query: ({ page = 1, limit = 10 }) => ({
//         url: /inquiry/admin/inquiries,
//         params: { page, limit },
//       }),
//       validateStatus: (response, result) => {
//         return response.status === 200;
//       },
//       transformResponse: (response) => {
//         return {
//           data: response.data ?? [],
//           total: response.total ?? 0,
//         };
//       },
//     }),
//   }),
//   overrideExisting: false,
// });

// export const {
//   useCreateInquiryMutation,
//   useGetAllInquiriesQuery,
// } = inquiryApiSlice;