import React from "react";
import { Box, Typography, Card, Button } from "@mui/material";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import CableIcon from "@mui/icons-material/Cable";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import { useNavigate } from "react-router-dom";

const userTestimonials = [
  { name: "Gas", backgroundColor: "purple", icon: <LocalFireDepartmentIcon sx={{ fontSize: "3rem" }} /> },
  { name: "Fiber", backgroundColor: "yellow", icon: <CableIcon sx={{ fontSize: "3rem" }} /> },
  { name: "Electricity", backgroundColor: "green", icon: <EmojiObjectsIcon sx={{ fontSize: "3rem" }} /> },
  { name: "Heat", backgroundColor: "orange", icon: <EmojiObjectsIcon sx={{ fontSize: "3rem" }} /> },
  { name: "Wastewater", backgroundColor: "brown", icon: <CableIcon sx={{ fontSize: "3rem" }} /> },
  { name: "Water", backgroundColor: "gray", icon: <LocalFireDepartmentIcon sx={{ fontSize: "3rem" }} /> },
 
];

const Offer = () => {
  const navigate = useNavigate();
  const requestQuote = () => {
    navigate("/addProject");
  };
  return (
    <Box
      sx={{
        backgroundColor: "white",
        overflow: "hidden",
        padding: 2,
        py: 5,
      }}
    >
      <Typography
       variant="h6"
      sx={{
    fontSize: { xs: "20px", sm: "40px" },
    fontWeight: 700,
    marginBottom: "20px",
    margin: "0 auto",
    lineHeight: { xs: "49px", sm: "81px" },
    color: "#336699",
    textAlign: "center" // This line centers the text
     }}
  >
  Need any Offer? Please Request a Quote
</Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 2,
          mt: 4,
        }}
      >
        {userTestimonials.map((testimonial, index) => (
          <Card
            key={index}
            sx={{
              width: 191,
              backgroundColor: testimonial.backgroundColor,
              borderRadius: "20px",
              boxShadow: 3,
              '&:hover': {
                boxShadow: 6,
              },
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
              }}
            >
              {testimonial.icon}
              <Typography variant="h5" fontWeight="bold" sx={{ mt: 1 }}>
                {testimonial.name}
              </Typography>
            </Box>
          </Card>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 4,
        }}
      >
        <Box sx={{ m: "8px 20px" }}>
          <Button
            variant="contained"
            onClick={requestQuote}
            sx={{
              backgroundColor: "#FFC400",
              color: "black",
              fontSize: 15,
              fontWeight: "bold",
              borderRadius: "20px",
              padding: "8px 24px",
              '&:hover': {
                backgroundColor: "#ffd54f",
              },
            }}
          >
            Request a Quote
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Offer;
