import React from 'react';
import GroupIcon from '@mui/icons-material/Group';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BusinessIcon from '@mui/icons-material/Business';

function MainListItems({ setActivePage }) {
  return (
    <React.Fragment>
      <ListItemButton onClick={() => setActivePage('stats')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={() => setActivePage('quotes')}>
        <ListItemIcon>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText primary="Quotes" />
      </ListItemButton>

      <ListItemButton onClick={() => setActivePage('customers')}>
         <ListItemIcon>
          <GroupIcon />
       </ListItemIcon>
       <ListItemText primary="Customers" />
     </ListItemButton>
     <ListItemButton onClick={() => setActivePage('companies')}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Companies" />
      </ListItemButton>
          <ListItemButton onClick={() => setActivePage('enquiries')}>
         <ListItemIcon>
          <ContactMailIcon />
        </ListItemIcon>
        <ListItemText primary="Enquiries" />
      </ListItemButton>
      <ListItemButton onClick={() => setActivePage('createAccount')}>
       <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        <ListItemText primary="Create Account" />
     </ListItemButton>


    </React.Fragment>
  );
}

export default MainListItems;