// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
// // import { setCredentials } from '../shared/authSlice'
// // import { setToken } from "../reducer/shared/authSlice";
// import { setToken } from '../reducer/shared/authSlice';
// // Define the base query with authentication headers
// const baseQuery = fetchBaseQuery({
//     baseUrl: 'http://localhost:3500',
//     credentials: 'include',
//     // prepareHeaders: (headers, { getState }) => {
//     //     const token = getState().auth.token

//     //     if (token) {
//     //         headers.set("Authorization", `Bearer ${token}`)
//     //     }
//     //     return headers
//     // }

//     prepareHeaders: (headers, { getState }) => {
//     const token = getState().auth.accessToken;  // Make sure this path correctly points to where the token is stored
//     console.log("Token from state:", token);  // This should log the correct token
//     if (token) {
//         headers.set("Authorization", `Bearer ${token}`);
//     }
//     return headers;
// }
// })

// // Define the base query with token refresh logic
// const baseQueryWithReauth = async (args, api, extraOptions) => {
//     let result = await baseQuery(args, api, extraOptions)

//     // Handle token expiration (example: 403 Forbidden)
//     if (result?.error?.status === 403) {
//         // Send refresh token to get a new access token
//         const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)

//         if (refreshResult?.data) {
//             // Store the new token 
//             // api.dispatch(setCredentials({ accessToken: refreshResult.data.accessToken }))
//             api.dispatch(setToken(refreshResult.data.accessToken));

//             // Retry the original query with the new access token
//             result = await baseQuery(args, api, extraOptions)
//         } else {
//             // Handle refresh token failure
//             if (refreshResult?.error?.status === 403) {
//                 refreshResult.error.data.message = "Your login has expired. "
//             }
//             return refreshResult
//         }
//     }

//     return result
// }

// export const apiSlice = createApi({
//     baseQuery: baseQueryWithReauth,
//     tagTypes: ['Note', 'User'],
//     endpoints: builder => ({})
// })



import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { logOut } from './shared/authSlice';
import {logOut} from '../reducer/shared/authSlice'

// const baseQuery = fetchBaseQuery({
//     baseUrl: 'http://localhost:3500',
//     prepareHeaders: (headers, { getState }) => {
//         const token = getState().auth.accessToken;
//         if (token) {
//             headers.set('Authorization', `Bearer ${token}`);
//         }
//         return headers;
//     },
// });
const baseQuery = fetchBaseQuery({
    baseUrl: 'https://www.api.infraverse.org',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.accessToken;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});





const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        api.dispatch(logOut());
    }
    return result;
};

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
});
