export const dashboardRecordHeaderTitle = {
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  color: "#212B36",
};

export const dashboardRecordCategoryTitle = {
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
};

export const dashboardRecordCategoryType = {
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "18px",
};

export const dashboardCardBorderRadius = 15;
