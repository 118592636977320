// import { useLocation, Navigate, Outlet } from "react-router-dom";
// import useAuth from "../../hook/useAuth";
// import PropTypes from "prop-types";

// const RequireAuth = ({ allowedUserType }) => {
//   const location = useLocation();
//   const { userType } = useAuth();

//   const content = allowedUserType.some((type) => type.includes(userType)) ? (
//     <Outlet />
//   ) : (
//     <Navigate to="/" state={{ from: location }} replace />
//   );

//   return content;
// };

// RequireAuth.propTypes = {
//   allowedUserType: PropTypes.array.isRequired,
// };

// export default RequireAuth;


import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hook/useAuth";
import PropTypes from "prop-types";

const RequireAuth = ({ allowedUserType }) => {
  const location = useLocation();
  const { userType } = useAuth();

  // Check if the current user's type is allowed to access the route
  const isAllowed = allowedUserType.includes(userType);

  return isAllowed ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
};

RequireAuth.propTypes = {
  allowedUserType: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RequireAuth;
