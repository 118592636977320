// import dots from '../assets/footer/dots.png.jpg'
export const bgDotsFooterStyle = {
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Adjust for desired transparency of the footer background itself
    '&::before, &::after': { 
      content: '""',
      position: 'absolute',
      top: '0',
      height: '100%',
      backgroundRepeat: 'no-repeat', // Changed to no-repeat to avoid tiling if you want larger, single images
      backgroundSize: '100% 100%', // Adjust to '100% 100%' for full cover of each element's area or set in pixels
    },
    '&::before': {
      left: '0',
      width: '25%',
    //   backgroundImage: url(${dots}),
    // backgroundImage: `url(${dots})`,
      transform: 'scaleX(-1)', // Flip horizontally if needed
      opacity: 0.5, // Adjust for better visibility
    },
    '&::after': {
      right: '0',
      width: '25%', 
    //   backgroundImage: `url(${dots})`,
      backgroundColor: 'transparent', // Ensure it's transparent
      opacity: 0.5, // Match the opacity
    },
  '@media (max-width: 767px)': {
    '&::before, &::after': {
      display: 'none', // Hide the background image on screens less than 768px wide
    },
  },
  };







  export const globalStyle = {
    fontFamily: "Raleway",
    fontStyle: "normal",
  };
  export const globalBodyStyle = {
    width: "100%",
  };
  export const globalTopLinkBoxStyle = {
    width: "100%",
    height: "5vh",
  };
  export const globalTopLinkTextStyle = {
    display: "inline-flex",
    alignItems: "center",
    marginLeft: 22,
    marginTop: 20,
    fontWeight: "600",
    fontSize: "14px",
    color: "#212B36",
    lineHeight: "12px",
    textTransform: "uppercase",
  };
  export const globalTopBoxStyle = {
    width: "100%",
    height: "8vh",
    mt: 4,
  };
  export const globalBottomBoxStyle = {
    width: "100%",
    height: "70vh",
    flexGrow: 1,
  };
  export const globalBottomBoxLeftSideStyle = {
    pt: 5,
    fontWeight: "600",
    fontSize: "20px",
    color: "#212B36",
    lineHeight: "19px",
  };
  export const globalBottomBoxRightSideStyle = {
    mt: 3,
    borderRadius: "15px",
    mb: { xs: 3 },
  };
  
  export const horizontalLineStyle = {
    border: "1px solid #EBEBEB",
    width: "99%",
  };
  export const globalSaveBtnStyle = {
    background: "#904B95",
    borderRadius: "3px",
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "13px",
    display: "inline",
    alignItems: "center",
    color: "#FFFFFF",
    ml: "8%",
    width: { xs: "40px", sm: "100px" },
    "&:hover": {
      background: "#904B95",
      color: "#FFFFFF",
    },
  };
  export const globalResetBtnStyle = {
    background: "#D9DFF4",
    borderRadius: "3px",
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "13px",
    display: "inline",
    alignItems: "center",
    color: "#878787",
    width: { xs: "40px", sm: "100px" },
    ml: "8%",
    "&:hover": {
      background: "#D9DFF4",
      color: "#878787",
    },
  };
  export const globalAddBtnStyle = {
    background: "#904B95",
    borderRadius: "3px",
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "13px",
    display: "inline",
    alignItems: "center",
    color: "#FFFFFF",
    width: { xs: "40px", sm: "100px" },
    "&:hover": {
      background: "#904B95",
      color: "#FFFFFF",
    },
  };
  export const globalEditBtnStyle = {
    background: "#D9DFF4",
    borderRadius: "3px",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "13px",
    display: "inline",
    alignItems: "center",
    color: "#878787",
    width: { xs: "40px", sm: "100px" },
    "&:hover": {
      background: "#D9DFF4",
      color: "#878787",
    },
  };
  export const globalModalStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    width: 350,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "3px",
    height: "100%",
    display: "flex",
  };
  export const globalAddPaper = {
    borderRadius: "15px",
    p: 2,
    mt: 4,
    ml: 4,
    mr: 4,
  };
  export const globalModalTitleStyle = {
    fontWeight: "bold",
    fontSize: "13px",
    color: "#565656",
    textTransform: "uppercase",
    lineHeight: "14px",
  };
  export const globalModalTextfieldStyle = {
    fontSize: "13px",
    fontWeight: "400",
    borderRadius: "3px",
  };
  export const globalModalTextfieldLabelStyle = {
    fontSize: "14px",
    fontWeight: "600",
  };
  export const globalModalBtnStyle = {
    textAlign: "center",
    color: "#FFFFFF",
    background: "#294289",
    borderRadius: "5px",
    fontSize: "11px",
    fontWeight: "500",
    textTransform: "uppercase",
    height: "38px",
  };