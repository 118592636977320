import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableCell,
  Tooltip,
  TablePagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import useGetAllInquiries from "../../hook/routing/useGetAllInquiries";

import {
 
  globalStyle,
 
} from "../../constant/globalStyle";

const columns = [
  { field: "inquiryId", headerName: "Enquiry ID", width: 130 },
  { field: "offerExpiryDate", headerName: "Offer Expiry Date", width: 130 },
  { field: "totalPrice", headerName: "Total Price", width: 130 },
  { field: "action", headerName: "Actions", width: 130 },
];

const AdminInquiries = () => {
  const { array, isLoading, refetch } = useGetAllInquiries();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const openAlertBox = async ({ title, message, confirmButtonText, cancelButtonText, onConfirm }) => {
    if (window.confirm(message)) {
      await onConfirm();
    }
  };

  const deleteUser = async (user) => {
    console.log("Deleting user:", user);
  };

  const handleDelete = async (object) => {
    try {
      await openAlertBox({
        title: "Confirmation",
        message: "Are you sure you want to permanently delete this record?",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        onConfirm: () => deleteUser(object),
      });
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Paper elevation={2} sx={{ borderRadius: "15px",my:15, mx: 4}}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align="left"
                    sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>Loading...</TableCell>
                </TableRow>
              ) : (
                array
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.Id}>
                      <TableCell>{row.inquiryId}</TableCell>
                      <TableCell>{row.offerExpiryDate}</TableCell>
                      <TableCell>{row.totalPrice}</TableCell>
                      <TableCell>
                        <Tooltip title="Delete">
                          <IconButton
                            color="error"
                            onClick={() => handleDelete(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={array.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </>
  );
};

export default AdminInquiries;