import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

const CustomerEnquires = () => {
  const { array: users, refetch, isLoading } = { array: [], refetch: () => {}, isLoading: false }; // Mocking hook return values
  const openAlertBox = async ({ title, message, confirmButtonText, cancelButtonText, onConfirm }) => {
    if (window.confirm(message)) {
      await onConfirm();
    }
  };
  const deleteUser = async (user) => {
    console.log("Deleting user:", user);
  };

  async function handleDelete(object) {
    try {
      await openAlertBox({
        title: "Confirmation",
        message: "Are you sure you want to permanently delete this record?",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        onConfirm: () => deleteUser(object),
      });
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  }

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <>
    

      <Grid>
        <Paper elevation={2} sx={{ borderRadius: "15px", m: 4 }}>
          <TableContainer>
            <Table aria-label="User Table">
              <TableHead>
                <TableRow>
                <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                  Enquire Id
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                  totalPrice
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                  offerExpiryDate
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                  description
                  </TableCell>
                  
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" ,px:10 }}>
                    Status
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                    Actions
                  </TableCell>
                </TableRow>
                <TableRow>
                <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                  444565
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                  50000
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                  07/11/2023
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                    heat
                  </TableCell>
                  
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                  
                  <Button>Approved</Button>
                  <Button>Declined</Button>
                 
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "700", fontSize: "16px" }}>
                  <VisibilityIcon />
                 
                  {/* <EditIcon />
                  <DeleteIcon /> */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={4}>Loading...</TableCell>
                  </TableRow>
                ) : (
                  users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.organizationName}</TableCell>
                      <TableCell>{user.organizationId}</TableCell>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>
                        <Tooltip title="Delete">
                          <IconButton
                            color="error"
                            cursor="pointer"
                            onClick={() => handleDelete(user)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        {/* Replace SummaryEdit with a placeholder */}
                        <div>Edit</div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </>
  );
};

export default CustomerEnquires;