// import React, { useEffect } from "react";
// import {
//   Table,
//   TableBody,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   TableCell,
//   Tooltip,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import useGetAllQuotes from "../../hook/routing/useGetAllQuotes";
// import { queryListener } from "../../constant/object";
// import { useDeleteQuoteMutation } from "../../redux/reducer/api/quoteApiSlice";
// import { useAlertDialogSlide } from "../../constant/alert-dialog/AlertDialogProvider";
// import { deleteMutation } from "../../util/helper-function";
// import {
//     globalAddPaper,
//     globalStyle,
//   } from "../../constant/globalStyle";
//   const columns = [
//     { field: "quoteId", headerName: "Quote ID", width: 130 },
//     { field: "services", headerName: "Services", width: 130 },
//     // { field: "services", headerName: "Routing Weight", width: 130 },
//     { field: "totalSiteLoad", headerName: "totalSiteLoad", width: 130 },
//     { field: "action", headerName: "Actions", width: 130 },
//   ];
// const AdminQuotes = () => {
//     const { array, isLoading, refetch } = useGetAllQuotes(   );
//       const [deleteQuoteMutation] = useDeleteQuoteMutation();
//   const { open: openAlertBox } = useAlertDialogSlide();

//   async function handleDelete(quote) {
//     console.log("Attempting to delete quote:", quote);
//     try {
//         await openAlertBox({
//             title: "Confirmation",
//             message: `Are you sure you want to permanently delete quote ID ${quote.quoteId}?`,
//             confirmButtonText: "Delete",
//             cancelButtonText: "Cancel",
//             onConfirm: () => {
//                 deleteQuoteMutation({ quoteId: quote.quoteId }) // Make sure this matches your data structure
//                     .unwrap()
//                     .then(() => {
//                         console.log("Deletion successful");
//                         refetch(); // Refresh the data
//                     })
//                     .catch((error) => {
//                         console.error("Deletion failed", error);
//                     });
//             },
//         });
//     } catch (err) {
//         console.error("Error during deletion process:", err);
//     }
// }


//   // async function handleDelete(quote) {
//   //   console.log("Attempting to delete quote:", quote);
//   //   try {
//   //     await openAlertBox({
//   //       title: "Confirmation",
//   //       message: `Are you sure you want to permanently delete quote ID ${quote.quoteId}?`,
//   //       confirmButtonText: "Delete",
//   //       cancelButtonText: "Cancel",
//   //       onConfirm: () => {
//   //         deleteQuoteMutation({ quoteId: quote._id }) // or quote.quoteId depending on your data structure
//   //           .unwrap()
//   //           .then(() => {
//   //             console.log("Deletion successful");
//   //             refetch();
//   //           })
//   //           .catch((error) => {
//   //             console.error("Deletion failed", error);
//   //           });
//   //       },
//   //     });
//   //   } catch (err) {
//   //     console.error("Error during deletion process:", err);
//   //   }
//   // }
  
//   // async function handleDelete(quote) {
//   //   console.log("Attempting to delete quote:", quote);
//   //   try {
//   //     await openAlertBox({
//   //       title: "Confirmation",
//   //       message: `Are you sure you want to permanently delete quote ID ${quote.quoteId}?`,
//   //       confirmButtonText: "Delete",
//   //       cancelButtonText: "Cancel",
//   //       onConfirm: () => {
//   //         deleteQuoteMutation({ quoteId: quote._id }) // Ensure you are passing the correct quote ID field
//   //           .unwrap()
//   //           .then(() => {
//   //             console.log("Deletion successful");
//   //             refetch();  // If using refetch to update the list post-deletion
//   //           })
//   //           .catch((error) => {
//   //             console.error("Deletion failed", error);
//   //           });
//   //       },
//   //     });
//   //   } catch (err) {
//   //     console.error("Error during deletion process:", err);
//   //   }
//   // }
//   return (
//     <>
//       <Paper elevation={2} sx={{ borderRadius: "15px", m: 4 }}>
//         <TableContainer>
//           <Table aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 <TableCell
//                   align="left"
//                   sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//                 >
//                   Quote ID
//                 </TableCell>
//                 <TableCell
//                   align="left"
//                   sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//                 >
//                    Services
//                 </TableCell>
//                 <TableCell
//                   align="left"
//                   sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//                 >
//                   totalSiteLoad
//                 </TableCell>
                
//                 <TableCell
//                   align="left"
//                   sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
//                 >
//                   Actions
//                 </TableCell>
//               </TableRow>
//             </TableHead>

//             <TableBody>
//               {isLoading ? (
//                 <TableRow>
//                   <TableCell colSpan={columns.length}>Loading...</TableCell>
//                 </TableRow>
//               ) : (
//                 array.map((row) => (
//                   <TableRow key={row.Id}>
//                    < TableCell>{row.quoteId}</TableCell>
//                     <TableCell>{row.services}</TableCell>
                   
//                     <TableCell>{row.totalSiteLoad}</TableCell>
//                     <TableCell>
//                       <>
//                         <Tooltip title="Delete">
                         
//                             <IconButton
//   color="error"
//   onClick={() => handleDelete(row)} // Ensure `row` contains the expected quote data
// >
//   <DeleteIcon />
// </IconButton>
//                           {/* </IconButton> */}
//                         </Tooltip> 

//                       </>
//                     </TableCell>
//                   </TableRow>
//                 ))
//               )}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Paper>
//     </>
//   );
// };

// export default AdminQuotes;





import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableCell,
  Tooltip,
  TablePagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useGetAllQuotes from "../../hook/routing/useGetAllQuotes";
import {
  globalStyle,
} from "../../constant/globalStyle";

const columns = [
  { field: "quoteId", headerName: "Quote ID", width: 130 },
  { field: "services", headerName: "Services", width: 130 },
  { field: "totalSiteLoad", headerName: "Total Site Load", width: 130 },
  { field: "action", headerName: "Actions", width: 130 },
];

const AdminQuotes = () => {
  const { array, isLoading, refetch } = useGetAllQuotes();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const openAlertBox = async ({ title, message, confirmButtonText, cancelButtonText, onConfirm }) => {
    if (window.confirm(message)) {
      await onConfirm();
    }
  };

  const deleteUser = async (user) => {
    console.log("Deleting user:", user);
  };

  const handleDelete = async (object) => {
    try {
      await openAlertBox({
        title: "Confirmation",
        message: "Are you sure you want to permanently delete this record?",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        onConfirm: () => deleteUser(object),
      });
    } catch (error) {
      console.error("Error during deletion:", error);
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
{/* <AdminAppbar/>
<AdminSidebar/> */}
    
      <Paper elevation={2} sx={{ borderRadius: "15px",my:15, mx: 4 }}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align="left"
                    sx={{ fontWeight: "700", ...globalStyle, fontSize: "16px" }}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>Loading...</TableCell>
                </TableRow>
              ) : (
                array
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.Id}>
                      <TableCell>{row.quoteId}</TableCell>
                      <TableCell>{row.services}</TableCell>
                      <TableCell>{row.totalSiteLoad}</TableCell>
                      <TableCell>
                        <Tooltip title="Delete">
                          <IconButton
                            color="error"
                            onClick={() => handleDelete(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={array.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </>
  );
};

export default AdminQuotes;