import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
// import Landing from "../feature/home";
const MainLayout = () => {
  return (
    <>
      <Box>
        <CssBaseline />

        <Header />
{/* <Landing/> */}
        <Outlet />

        <Footer />
      </Box>
    </>
  );
};

export default MainLayout;
