import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { Lock,AccountCircle } from "@mui/icons-material";
import { TextField, InputAdornment, Typography, Grid } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLoginMutation } from "../../redux/reducer/api/authApiSlice";


import * as Yup from "yup";
import useAuth from '../../hook/useAuth'
export default function SignIn() {
    const { isIntegrationEngineer, isAdmin, isCustomer, isCompany } = useAuth();
    const navigate = useNavigate();
    const [login, { isLoading }] = useLoginMutation();
    // const accessToken=useSelector(selectCurrentToken);
    // console.log("Token issssssss: ",accessToken )
    const validationSchema = Yup.object({
        // username: Yup.string()
        //     .email("Invalid email address")
        //     .required("Email is required"),

        // username: Yup.string().notOneOf( "This username is not allowed").required("Username is required"),
        password: Yup.string().required("Password is required"),
    });

    const initialValues = {
        username: "",
        password: "",
    };

    // const handleSubmit = async (values, { setFieldError }) => {
    //     try {
    //         // const { username, password } = values;
    //         const payload={...values}
    //         const response = await login({ ...payload }).unwrap();
    //         if (response.success) {
    //             console.log("Login Sucess")
    //             navigate('/welcome');
    //         } else {
    //             notification.error(response.message || "Failed to login");
    //         }
    //     } catch (error) {
    //         notification.apiFailure(error.data?.message || "Exception: Failed to call api");
    //     }
    // };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await login(values).unwrap();
            if (response.accessToken) {
                console.log("Login successful: token received", response.accessToken);
                if (isAdmin){
                navigate('/admindashboard');
                }
                else if (isCompany){
                    navigate('/Companydashboard');
                    }
                    else if (isCustomer){
                        navigate('/dashboard');
                        }
                        else if (isIntegrationEngineer){
                            navigate('/SuperAdminDashboard');
                            }

            } else {
                console.log("Login successful: no token received");
            }
        } catch (error) {
            console.error("Login error:", error);
            alert("Failed to login, please check your credentials.");
        } finally {
            setSubmitting(false);
        }
    };
    return (
        <Container component="main" maxWidth="xs" sx={{ flexGrow: 1, p: 1, bgcolor: "white", borderRadius: "5px" }}>
            <Box sx={{ marginTop: 15, marginBottom: 10, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    <Form>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                                <Field name="username" as={TextField} fullWidth id="username" label="Username"
                                    autoComplete="username"
                                    InputProps={{ startAdornment: (<InputAdornment position="start">
                                    <AccountCircle />
                                  </InputAdornment>) }}
                                />
                                <Typography variant="body2" sx={{ color: "red", pt: 1 }}>
                                    <ErrorMessage name="username" />
                                </Typography>
                                </Grid>
                            {/* <Grid item xs={12}>
                                <Field name="username" as={TextField} fullWidth id="username" label="Email Address"
                                    autoComplete="username"
                                    InputProps={{ startAdornment: (<InputAdornment position="start"><Email /></InputAdornment>) }}
                                />
                                <Typography variant="body2" sx={{ color: "red", pt: 1 }}>
                                    <ErrorMessage name="username" />
                                </Typography> */}
                            {/* </Grid> */}
                            <Grid item xs={12}>
                                <Field name="password" as={TextField} fullWidth type="password" id="password" label="Password"
                                    autoComplete="new-password"
                                    InputProps={{ startAdornment: (<InputAdornment position="start"><Lock /></InputAdornment>) }}
                                />
                                <Typography variant="body2" sx={{ color: "red", pt: 1 }}>
                                    <ErrorMessage name="password" />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isLoading}>
                            Login
                        </Button>
                        <Grid container sx={{display:"flex",flexDirection:"row"}}>
                        <Grid item justifyContent="flex-start">
                                <Link to="/forgetpassword" variant="body2">
                                    {"Forget Password"}
                                </Link>
                            </Grid>
                            <Grid item justifyContent="flex-end" mx={"55px"}>
                                <Link to="/signup" variant="body2">
                                    {" Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                        
                    </Form>
                </Formik>
            </Box>
        </Container>
    );
}
