// import React from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import MainLayout from "./MainLayout";
// import Landing from "../feature/home";
// import Aboutus from "../feature/MenuPage/Aboutus";
// import OurGoals from "../feature/MenuPage/OurGoals";

// import OurValues from "../feature/MenuPage/OurValues";

// import SignUp from "../feature/authentication/Signup";
// import SignIn from "../feature/authentication/Login";
// import Userdetails from "../feature/Project/AddProject";
// import AddBid from "../feature/Bid/AddBid";
// import Welcome from "../feature/authentication/Welcome";
// import ForgetPassword from "../feature/authentication/ForgetPassword";
// // import Dashboard from "../dashboardlayout/Dashboard";

// import Dashboard from "../feature/authentication/dashboard";
// import AdminDashboard from "../feature/authentication/AdminDashboard";
// import CompanyDashboard from "../feature/authentication/CompanyDashboard";
// import SuperAdminDashboard from "../feature/authentication/SuperAdminDashboard";
// import CustomerQuotes from "../feature/authentication/CustomerQuotes";
// import CustomerEnquires from "../feature/authentication/CustomerEnquires";
// import AdminQuotes from "../feature/authentication/AdminQuotes";

// import { useSelector } from "react-redux";
// import {userTypeObject} from '../constant/object'
// import { logOut, selectCurrentToken } from "../redux/reducer/shared/authSlice";

// import RequireAuth from "../feature/authentication/RequireAuth";
// import AdminInquiries from "../feature/authentication/AdminInquiries";




// const AppRoute = () => {
//   const accessToken = useSelector(selectCurrentToken);
//   const { Customer, Company } = userTypeObject;
//   return (
//     <BrowserRouter>
//       <Routes>
//       <Route
//               element={
//                 <RequireAuth
//                   allowedUserType={[...Object.values(userTypeObject)]}
//                 />
//               }
//             >
//         {/* <Route path="/dashboard" element={<Dashboard/>}/> */}
//         <Route path="/dashboard" element={<Dashboard />} />
//           {/* <Route path="/dashboard/profile" element={<Profile />} /> */}
//           <Route path="/dashboard/customerquotes" element={<CustomerQuotes />} />
//           <Route path="/dashboard/customerenquires" element={<CustomerEnquires />} />

//           <Route path="/admindashboard" element={<AdminDashboard />} />
//           <Route path="/admindashboard/adminquotes" element={<AdminQuotes />} />
//           <Route path="/admindashboard/admininquiries" element={<AdminInquiries />} />
//           <Route path="/superadmindashboard" element={<SuperAdminDashboard />} />
//           <Route path="/companydashboard" element={<CompanyDashboard />} />

//         <Route path="/" element={<MainLayout />} />
//         <Route path="/" element={<Landing />} />
//         <Route path="/addproject" element={<Userdetails />} />
//         </Route>
//           <Route path="/signup" element={<SignUp />} />
//           <Route path="/login" element={<SignIn />} />
//           <Route path="/forgetpassword" element={<ForgetPassword/>}/>
//           <Route path="/addproject" element={<Userdetails />} />
//           <Route path="/addbid" element={<AddBid />} />
//           <Route path="/welcome" element={<Welcome/>}/>
          
//           <Route path="/aboutus" element={<Aboutus />}/>
//           <Route path="/ourgoals" element={<OurGoals />}/>
          
//           <Route path="/ourvalues" element={<OurValues />}/>
//       </Routes>
//     </BrowserRouter>
//   );
// };
// export default AppRoute;

// // {/* <Route element={<MainLayout />}> */}//  </Route> 


// //  <BrowserRouter>
// {/* <Routes>
    
// {accessToken ? (
//  <>
// <Route path="/dashboard" element={<Dashboard/>}/>
// <Route path="/" element={<MainLayout />} />
// <Route path="/addproject" element={<Userdetails />} />
// </>
// ) : (
// <>
//  <Route path="/signup" element={<SignUp />} />
//  <Route path="/login" element={<SignIn />} />
//  <Route path="/forgetpassword" element={<ForgetPassword/>}/>
//  <Route path="/addproject" element={<Userdetails />} />
//  <Route path="/addbid" element={<AddBid />} />
//  <Route path="/welcome" element={<Welcome/>}/>
//  <Route path="/" element={<MainLayout />} />
//  </>
// )}
// </Routes>
// </BrowserRouter> */}




import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./MainLayout";
import SignUp from "../feature/authentication/Signup";
import SignIn from "../feature/authentication/Login";
import Userdetails from "../feature/Project/AddProject";
import AddBid from "../feature/Bid/AddBid";
import Welcome from "../feature/authentication/Welcome";
import ForgetPassword from "../feature/authentication/ForgetPassword";
import Landing from "../feature/home";
import Aboutus from "../feature/MenuPage/Aboutus";
import OurGoals from "../feature/MenuPage/OurGoals";

import OurValues from "../feature/MenuPage/OurValues";
// import Dashboard from "../dashboardlayout/Dashboard";

import Dashboard from "../feature/authentication/dashboard";
import AdminDashboard from "../feature/authentication/AdminDashboard";
import CompanyDashboard from "../feature/authentication/CompanyDashboard";
import SuperAdminDashboard from "../feature/authentication/SuperAdminDashboard";
import CustomerQuotes from "../feature/authentication/CustomerQuotes";
import CustomerEnquires from "../feature/authentication/CustomerEnquires";
import AdminQuotes from "../feature/authentication/AdminQuotes";

import { useSelector } from "react-redux";
import {userTypeObject} from '../constant/object'
import { logOut, selectCurrentToken } from "../redux/reducer/shared/authSlice";

import RequireAuth from "../feature/authentication/RequireAuth";
import AdminInquiries from "../feature/authentication/AdminInquiries";




const AppRoute = () => {
  const accessToken = useSelector(selectCurrentToken);
  const { Customer, Company } = userTypeObject;
  return (
    <BrowserRouter>
      <Routes>
      <Route
              element={
                <RequireAuth
                  allowedUserType={[...Object.values(userTypeObject)]}
                />
              }
            >
        {/* <Route path="/dashboard" element={<Dashboard/>}/> */}
        <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/dashboard/profile" element={<Profile />} /> */}
          <Route path="/dashboard/customerquotes" element={<CustomerQuotes />} />
          <Route path="/dashboard/customerenquires" element={<CustomerEnquires />} />

          <Route path="/admindashboard" element={<AdminDashboard />} />
          <Route path="/admindashboard/adminquotes" element={<AdminQuotes />} />
          <Route path="/admindashboard/admininquiries" element={<AdminInquiries />} />
          <Route path="/superadmindashboard" element={<SuperAdminDashboard />} />
          <Route path="/companydashboard" element={<CompanyDashboard />} />

        <Route path="/" element={<MainLayout />} >
        <Route path="/" element={<Landing />} />
        <Route path="/addproject" element={<Userdetails />} />
       
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/forgetpassword" element={<ForgetPassword/>}/>
          <Route path="/addproject" element={<Userdetails />} />
          <Route path="/addbid" element={<AddBid />} />
          <Route path="/welcome" element={<Welcome/>}/>
          <Route path="/aboutus" element={<Aboutus />}/>
          <Route path="/ourgoals" element={<OurGoals />}/>
          
        <Route path="/ourvalues" element={<OurValues />}/>
          {/* <Route path="/" element={<MainLayout />} /> */}
          </Route>
          </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default AppRoute;

// {/* <Route element={<MainLayout />}> */}//  </Route> 


//  <BrowserRouter>
{/* <Routes>
    
{accessToken ? (
 <>
<Route path="/dashboard" element={<Dashboard/>}/>
<Route path="/" element={<MainLayout />} />
<Route path="/addproject" element={<Userdetails />} />
</>
) : (
<>
 <Route path="/signup" element={<SignUp />} />
 <Route path="/login" element={<SignIn />} />
 <Route path="/forgetpassword" element={<ForgetPassword/>}/>
 <Route path="/addproject" element={<Userdetails />} />
 <Route path="/addbid" element={<AddBid />} />
 <Route path="/welcome" element={<Welcome/>}/>
 <Route path="/" element={<MainLayout />} />
 </>
)}
</Routes>
</BrowserRouter> */}