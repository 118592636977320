// import { useSelector } from "react-redux";
// // import jwtDecode from "jwt-decode";
// import { jwtDecode } from "jwt-decode";
// import { selectCurrentToken } from "../redux/reducer/shared/authSlice";
// import { extractNameFromEmail } from "../util/string";
// import { userTypeObject } from "../constant/object";

// const useAuth = () => {
//   const accessToken = useSelector(selectCurrentToken);

//   let id = null;
//   let isAdmin = false;
//   let isIntegrationEngineer = false;
//   let isCustomer = false;
//   let isCompany = false;
//   let userType = userTypeObject.Company;
//   let displayName = "";
//   // let organizationId = null;
//   let userName = null;

//   if (accessToken) {
//     const decoded = jwtDecode(accessToken);
//     const { userType: role } = decoded?.userIdentity;

//     id = decoded?.userIdentity?.id;
//     // organizationId = decoded?.userIdentity?.organizationId;
//     userName = decoded?.userIdentity?.username;

//     isAdmin = role?.includes("Admin");
//     isIntegrationEngineer = role?.includes(" IntegrationEngineer");
// isCustomer = role?.includes("Customer");
//     isCompany = role?.includes("Company");

//     if (isAdmin) userType = userTypeObject.Admin;
//     if (isIntegrationEngineer) userType = userTypeObject.IntegrationEngineer;
//     if (isCustomer) userType = userTypeObject.Customer;
//     if (isCompany) userType = userTypeObject.Company;

//     displayName = extractNameFromEmail(userName);
//   }

//   return {
//     id,
//     displayName,
//     userName,
//     userType,
//     // organizationId,
//     isAdmin,
//     isIntegrationEngineer,
//     isCustomer,
//     isCompany,
//   };
// };

// export default useAuth;


// import { useSelector } from "react-redux";
// // import jwtDecode from "jwt-decode";
// import { jwtDecode } from "jwt-decode";
// import { selectCurrentToken } from "../redux/reducer/shared/authSlice";
// import { extractNameFromEmail } from "../util/string";
// import { userTypeObject } from "../constant/object";

// const useAuth = () => {
//   const accessToken = useSelector(selectCurrentToken);
//   let userDetails = {
//     id: null,
//     isAdmin: false,
//     isIntegrationEngineer: false,
//     isCustomer: false,
//     isCompany: false,
//     userType: userTypeObject.Company,
//     displayName: "",
//     userName: null,
//   };
// console.log("Token",accessToken)
//   if (accessToken) {
//     try {
//       const decoded = jwtDecode(accessToken);
//       // const role = decoded?.userIdentity?.userType || "";
//       const role = decoded?.UserInfo?.role || [];
// console.log("Decoded",decoded);
// console.log("Role",role)
//       userDetails = {
//         ...userDetails,
//         id: decoded?.userIdentity?.id,
//         userName: decoded?.userIdentity?.username,
//         isAdmin: role.includes("Admin"),
//         isIntegrationEngineer: role.includes("IntegrationEngineer"),
//         isCustomer: role.includes("Customer"),
//         isCompany: role.includes("Company"),
//         userType: userTypeObject[role] || userTypeObject.Company,
//         displayName: extractNameFromEmail(decoded?.userIdentity?.username),
//       };
//     } catch (error) {
//       console.error("Failed to decode JWT:", error);
//     }
//   }

//   return userDetails;
// };

// export default useAuth;



import { useSelector } from "react-redux";
import {jwtDecode} from "jwt-decode";
import { selectCurrentToken } from "../redux/reducer/shared/authSlice";
import { extractNameFromEmail } from "../util/string";
import { userTypeObject } from "../constant/object";

const useAuth = () => {
  const accessToken = useSelector(selectCurrentToken);
  let userDetails = {
    id: null,
    isAdmin: false,
    isIntegrationEngineer: false,
    isCustomer: false,
    isCompany: false,
    userType: userTypeObject.Customer, // Default to Customer if role not matched
    displayName: "",
    userName: null,
  };

  if (accessToken) {
    try {
      const decoded = jwtDecode(accessToken);
      const roles = decoded?.UserInfo?.roles || [];
      console.log("Decoded",decoded);
      console.log("Role",roles)
      userDetails = {
        ...userDetails,
        id: decoded?.UserInfo?._id,
        userName: decoded?.UserInfo?.username,
        isAdmin: roles.includes("Admin"),
        isIntegrationEngineer: roles.includes("IntegrationEngineer"),
        isCustomer: roles.includes("Customer"),
        isCompany: roles.includes("Company"),
        displayName: extractNameFromEmail(decoded?.UserInfo?.username),
      };
console.log("UserDetails",userDetails)
      // Assign the first valid role found as userType for simpler role checks
      for (const role of roles) {
        if (userTypeObject[role]) {
          userDetails.userType = userTypeObject[role];
          break;
        }
      }
    } catch (error) {
      console.error("Failed to decode JWT:", error);
    }
  }

  return userDetails;
};

export default useAuth;
