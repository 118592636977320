import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import ButtonBase from "@mui/material/ButtonBase";

const userTestimonials = [
  {
    name: "Innovation",
    testimonial:
      "We prioritize innovation in all aspects of our business",
    backgroundColor: "#34B1BF",
    color:"white",
  },
  {
    name: "Sustainability",
    testimonial:
      "Committed to sustainability and environmental responsibility",
    backgroundColor: "#EFB245",
    color: "white",
  },
  {
    name: "Customer-Centricity",
    testimonial:
      "Our customers are at the heart of everything we do",
    backgroundColor: "#336699",
    color: "white",
  },
  {
    name: "Integrity",
    testimonial:
      "Honesty, transparency, and ethical integrity guide us.",
    backgroundColor: "#00336a",
    color: "white",
  },
];



export default function OurValues() {
  return (
    <Box
      id="testimonials"
      sx={{
        // backgroundImage: `url(${bg1})`, // Add the path to your background image
        backgroundSize: "cover",
        backgroundPosition: "center",
        py:"40px",
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        px: "20px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "70%" },
          textAlign: { xs: "center", sm: "center", md: "center" },
        }}
      >
        <Typography sx={{ fontSize: "44px",py:"30px" }}>OUR Values</Typography>

        {/* <Typography sx={{ fontSize: "24px" }}>
          Through the continuous innovation of technology and services, help
          users improve oil recovery and win-win with users.
        </Typography> */}
      </Box>

      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} sx={{ display: "flex" }}>
            <ButtonBase
              sx={{
                display: "block",
                textDecoration: "none", // Remove underline from the button
                borderRadius: "8px", // Add border radius to match Card appearance
              }}
              onClick={() => {
                // Handle button click event here
              }}
            >
<Card
  sx={{
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    pt: { xs: 4, sm: 12 },
    pb: { xs: 8, sm: 12 },
    p: 1,
    backgroundColor: testimonial.backgroundColor, // Set background color to transparent
    backgroundImage: `url(${testimonial.backgroundImage})`, // Set the background image
    backgroundRepeat: "no-repeat", // Prevent background image from repeating
    backgroundSize: "cover", // Adjust background size to cover the entire card,
    color: testimonial.color,
    height: "400px",
    
    visibility: "visible", // Set visibility to visible
    animationName: "fadeInUp", // Apply the fadeInUp animation
    animationDuration: "1s", // Set animation duration to 1 second
    transition: "transform 0.3s ease", // Add transition for smooth animation
    "&:hover": {
      transform: "translateY(-20px)", // Move the card up by 10px on hover
      
      filter: "invert(75%)",
    },
  }}
>
                <Box
                  sx={{
                    textAlign: "center",
                    pr: 2,
                  }}
                >
                  <CardHeader title={testimonial.name} />
                  <CardContent>
                    <Typography>{testimonial.testimonial}</Typography>
                  </CardContent>
                  <ArrowForwardSharpIcon color="white" />
                </Box>
              </Card>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
   
     
    </Box>
  );
}
